@use 'src/styles/config' as *;

.root {
  display: grid;
  grid-template-columns: 18rem 1fr;
  height: 100%;
  border-radius: 0.8rem;
  background: #fff;
  overflow: hidden;
  border: 1px solid $color-grey-light;
  & > * {
    min-width: 0;
  }
}

.wrap {
  padding: 1.8rem 2rem 2rem;
}
.tabs {
  background: $color-grey-light-2;
  padding: 1rem 0;
  &Indicator {
    right: auto;
    left: 0;
    width: 0.3rem;
  }
}
.tab {
  align-items: flex-start;
  text-transform: capitalize;
  min-width: inherit;
  padding: 1rem 2rem;
  min-height: 4.4rem;
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;
  margin-bottom: -1px;
}
.tabSelected {
  background: #fff;
  font-weight: 600;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .root {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
  .tabs {
    padding: 0;
  }
  .tab {
    border: 0;
    border-right: 1px solid $color-grey-light;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .tabs {
    min-height: 3.6rem;
  }
  .tab {
    min-height: 3.6rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
}
