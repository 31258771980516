@use 'src/styles/config' as *;

.root {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  text-transform: none;
  grid-gap: 1rem;
  padding: 0.5rem 1rem 0.5rem;
  margin: 0 -1rem;
  text-align: right;
  max-width: 26rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  > * {
    min-width: 0;
  }
}

.avatar {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.icon {
  transition: transform ease-in 0.3s;
  transform-origin: center;
  &Open {
    transform: scaleY(-1);
  }
}

.title {
  color: $color-grey;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.04rem;
}

@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
  .root {
    grid-template-columns: repeat(2, auto);
    grid-gap: 0.5rem;
  }
}
