@use 'src/styles/config' as *;
.root {
}
.item {
  &Icon {
    min-width: $panel-width;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &Text {
    &Inner {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
    }
  }

  // states
  &Inner {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  &:not(.itemInner) {
    min-height: 4.9rem;
  }
}
.icon {
  transition: transform ease-in 0.3s;
  transform-origin: center;
  color: $color-grey;
  &Open {
    transform: scaleY(-1);
  }
}
