@use 'src/styles/config' as *;
.root {
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  min-width: $panel-width;
  min-height: $header-height;
}
