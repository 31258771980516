@import 'src/styles/config/colors';

.root {
  --amount: 20;
}

$animationDuration: 3s;
$amount: 6;
$particleRadius: 2.4rem;
.item {
  width: $particleRadius * 2;
  height: $particleRadius * 2;
  border-radius: $particleRadius;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 0.8);
  animation-iteration-count: infinite;
  animation-duration: $animationDuration;
  top: calc(50% - #{$particleRadius});
  left: 50%;
  transform-origin: ($particleRadius * -1) center;
  box-shadow: inset -0.5rem -0.5rem 1.5rem rgba(0, 0, 0, 0.5);
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: radial-gradient(
      circle at 30% 30%,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(150, 150, 150, 0) 50%
    );
    right: 0;
    border-radius: $particleRadius;
  }

  $colors: (#022693, #5faf59, #e12037, #f7b800);

  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      background: nth($colors, random(length($colors)));
      animation-delay: calc(($i / $amount) * $animationDuration * -1);
      opacity: 0;
    }
  }
}

@keyframes move {
  0% {
    transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
  }
  30% {
    opacity: 1;
  }
  100% {
    z-index: 10;
    transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
  }
}
