$color-primary: #0b1e60;
$color-primary-light: rgba($color-primary, 0.5);

$color-secondary: #022693;
$color-secondary-light: rgba($color-secondary, 0.5);
$color-secondary-lightest: rgba($color-secondary, 0.3);

$color-black: rgba(#000, 0.87);
$color-grey: #95a2a7;
$color-grey-light: #e6ebf1;
$color-grey-light-2: #f5f6f7;
$color-grey-dark: #333;

$color-red: #e12037;
$color-green: #5faf59;

$color-page-background: #f2f3f4;
$color-white: #fff;
