@use 'src/styles/config' as *;
.root {
  box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);
  background-color: $color-white;
  border-bottom: 1px solid $color-grey-light;
}
.inner {
  min-height: $header-height;
  display: grid;
  grid-template-columns: 1fr auto;
}
